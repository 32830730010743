.App {
  font-family: sans-serif;
  text-align: center;
}

.input {
  margin-top: 10px;
  width: 100%;
}

.form {
  width: 70%;
  margin: auto;
}
